@import '../project-variables';
@import '../project-mixins';

.page-slider-cont {
	position:relative;
	.slider {
		height:46rem;
		.slide {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			background-size:cover;
			background-position:center center;
			height:46rem;
			h1 {
				font-size:6.4rem;
				text-align:center;
				font-family: $_STDFont;
				color: $WHITE;
				text-transform: uppercase;
				line-height:130%;
				line-height:1.3;
				text-shadow: 0px 5px 5px rgba(0,0,0, 0.4);
				@include breakWidth(740) {
					font-size:3.5rem;
				}
			}
			&.slide1 {
				background-image:url(../../images/page-sliders/home-slide-bg-01.jpg);
				@include breakWidth(1600) {
					background-image:url(../../images/page-sliders/home-slide-bg-01-1600x0.jpg);
				}
				@include breakWidth(1280) {
					background-image:url(../../images/page-sliders/home-slide-bg-01-1280x0.jpg);
				}
				@include breakWidth(800) {
					background-image:url(../../images/page-sliders/home-slide-bg-01-800x0.jpg);
				}
			}
			&.slide2 {
				background-image:url(../../images/page-sliders/home-slide-bg-02.jpg);
				@include breakWidth(1600) {
					background-image:url(../../images/page-sliders/home-slide-bg-02-1600x0.jpg);
				}
				@include breakWidth(1280) {
					background-image:url(../../images/page-sliders/home-slide-bg-02-1280x0.jpg);
				}
				@include breakWidth(800) {
					background-image:url(../../images/page-sliders/home-slide-bg-02-800x0.jpg);
				}
			}
			&.slide3 {
				background-image:url(../../images/page-sliders/home-slide-bg-03.jpg);
				@include breakWidth(1600) {
					background-image:url(../../images/page-sliders/home-slide-bg-03-1600x0.jpg);
				}
				@include breakWidth(1280) {
					background-image:url(../../images/page-sliders/home-slide-bg-03-1280x0.jpg);
				}
				@include breakWidth(800) {
					background-image:url(../../images/page-sliders/home-slide-bg-03-800x0.jpg);
				}
			}
		}
	}
}




.bestseller-h2 {
	color: $THEMECOLOR;
	text-align:center;
	font-family: $_STDFont;
	font-size: 5.5rem;
	font-weight:600;
	padding:4rem 3rem 4rem 3rem;
	margin:0 0 0 0;
	text-transform: uppercase;
}

@import '../plugins/owl.carousel.min.css';